import {useEffect, useRef, useState} from "react"
import {animated, useSpring, easings} from "@react-spring/web"

import ChatForm from "../ChatForm/ChatForm"
import ChatModelCard from "../ChatModelCard/ChatModelCard"
import Options from "../ChatFolder/Options/Options"
import Gifts from "../ChatFolder/Gifts/Gifts"
import BotInfo from "../ChatFolder/BotInfo/BotInfo"
import ChatMessage from "../ChatMessage/ChatMessage"
import ChatColor from "../ChatColor/ChatColor"

import {colors, getOptions} from "../../../configs/chat"
import {GLOBAL_BRAND} from "../../../utils/constants"
import {isEmptyObject} from "../../../utils/helpers"
import {useTopPadding} from "../../../hooks/useTopPadding"

import styles from "./Chat.module.scss"

export default function Chat({setSend, models, maxBenefit}) {
	const isBlockVisible = useTopPadding("botFixed")
	const scrollRef = useRef()
	const stickyRef = useRef()
	const [step, setStep] = useState(0)
	const [activeModel, setActiveModel] = useState({})
	const [complectationOptions, setComplectationOptions] = useState([])
	const [shownComplectationOptions, setShownComplectationOptions] = useState([])
	const [activeColor, setActiveColor] = useState({})
	const [activeOptions, setActiveOptions] = useState([])
	const [shownActiveOptions, setShownActiveOptions] = useState([])
	const [writes, setWrites] = useState(false)
	const [acceptedComplectationOptions, setAcceptedComplectationOptions] = useState(false)
	const [acceptedColor, setAcceptedColor] = useState(false)
	const [acceptedOption, setAcceptedOption] = useState(false)
	const message = `${activeModel?.name || ""} ${complectationOptions[0] || ""} ${activeColor?.text || ""} ${activeOptions || ""}`

	const chooseOptions = (arr) => arr.map((item) => item).join("<br />")

	const chooseComplectationOptions = (arr) => {
		if (!arr.length) return "Комплектация не выбраны"
		return arr.map((item) => item).join("<br />")
	}

	const scrollTo = (ref, position) => ref.current.scrollIntoView({behavior: "smooth", block: position})

	const firstSteps = (i) => {
		setStep(i + 1)
		setWrites(false)
		if (step > 3) scrollTo(scrollRef, "center")
	}

	const incStep = async (crossedStep = 0) => {
		let promise = new Promise(() => {
			setTimeout(
				() => {
					if (step < 9) setWrites(true)
				},
				step < 4 ? 200 : 0,
			)
			setTimeout(firstSteps, 2400, crossedStep || step)
		})
		return await promise
	}

	useEffect(() => {
		if (isEmptyObject(activeModel) && step === 3) return
		if (step > 2) scrollTo(scrollRef, "center")
	}, [writes])

	const chooseModel = (model) => {
		if (!isEmptyObject(activeModel)) {
			setStep(3)
			incStep(3)
		} else {
			incStep()
		}
		setActiveModel(model)
	}

	const chooseColor = (color) => {
		if (!isEmptyObject(activeColor)) {
			setStep(5)
			incStep(5)
		} else {
			incStep()
		}
		setAcceptedColor(true)
		setActiveColor(color)
	}

	const acceptComplectationOptions = () => {
		if (acceptedComplectationOptions) return setShownComplectationOptions(complectationOptions)
		setShownComplectationOptions(complectationOptions)
		setAcceptedComplectationOptions(true)
		incStep()
	}

	const acceptOption = () => {
		if (acceptedOption) return setShownActiveOptions(activeOptions)
		setShownActiveOptions(activeOptions)
		setAcceptedOption(true)
		incStep()
	}

	//checkComplectations
	useEffect(() => {
		if (step <= 4) {
			setActiveColor({})
			setAcceptedColor(false)
			setComplectationOptions([])
			setAcceptedComplectationOptions(false)
		}
	}, [step])

	//checkColor
	useEffect(() => {
		if (step <= 5) {
			setActiveOptions([])
			setAcceptedOption(false)
		}
	}, [step])

	//checkTypeBuy
	useEffect(() => {
		if (step <= 4) {
			setActiveOptions([])
			setAcceptedOption(false)
		}
	}, [step])

	//checkClearOptions
	useEffect(() => {
		if (complectationOptions.length === 0 && acceptedComplectationOptions) {
			setComplectationOptions([])
			setAcceptedComplectationOptions(false)
			setStep(4)
		}
	}, [complectationOptions])

	//checkClearBuy
	useEffect(() => {
		if (activeOptions.length === 0 && acceptedOption) {
			setActiveOptions([])
			setAcceptedOption(false)
			setStep(6)
		}
	}, [activeOptions])

	useEffect(() => {
		if (step > 6 && step <= 9) incStep()
		if (step >= 3) return
		incStep()
	}, [step])

	const springs = useSpring({
		from: {y: 300, opacity: 0, scale: 0.9},
		to: {y: 0, opacity: 1, scale: 1},
		delay: 500,
		transition: "ease",
		config: {
			duration: 500,
			easing: easings.easeOutCubic,
		},
	})

	return (
		<animated.div style={{...springs}} className={styles.root}>
			<BotInfo isBlockVisible={isBlockVisible} />
			<Gifts maxBenefit={maxBenefit} />
			<div className={styles.messages}>
				<div className={styles.container}>
					<div className={styles.messageWrapper}>
						{writes && step === 0 && <div className={styles.loading}>Печатаю…</div>}
						{step > 0 && (
							<ChatMessage>
								<div className={styles.image}>
									<img id='botFixed' src='/images/bot.png' alt='' width={100} height={100} />
								</div>
								Здравствуйте! <br />
								<span className={styles.botText}>На связи МАКСИМУМ. Официальный дилер {GLOBAL_BRAND} в России.</span>
							</ChatMessage>
						)}
					</div>
					{step > 1 && (
						<ChatMessage>
							Пожалуйста, ответьте на пару вопросов, чтобы я могла составить для вас предложение с вариантами комплектации и стоимостью.
						</ChatMessage>
					)}
				</div>
				{step > 2 && (
					<>
						<div className={styles.container}>
							<ChatMessage>
								Какую модель автомобиля вы рассматриваете?
								<br />
								Выберите автомобиль из списка ниже:
							</ChatMessage>
						</div>
						<div ref={stickyRef} className={styles.container}>
							<div className={styles.models}>
								{models.map((model) => (
									<ChatModelCard key={model.name} model={model} activeModel={activeModel} chooseModel={chooseModel} />
								))}
							</div>
						</div>
						<div className={styles.container}>
							<ChatMessage>Выберите автомобиль из списка выше</ChatMessage>
						</div>
					</>
				)}
				<div className={styles.container}>
					{!isEmptyObject(activeModel) && <ChatMessage user>{activeModel.name}</ChatMessage>}

					{step > 3 && (
						<>
							<ChatMessage>
								Выберите комплектацию:
								<div className={styles.complectations}>
									{activeModel.options.map((option) => (
										<Options key={option} option={option} setFunction={setComplectationOptions} isComplectation />
									))}
								</div>
							</ChatMessage>
							<button
								className={`${styles.button} ${acceptedComplectationOptions ? styles.buttonActive : ""}`}
								onClick={acceptComplectationOptions}
							>
								Продолжить
							</button>
						</>
					)}
					{acceptedComplectationOptions && (
						<ChatMessage user>
							<div style={{display: "inline-block"}} dangerouslySetInnerHTML={{__html: chooseComplectationOptions(shownComplectationOptions)}} />
						</ChatMessage>
					)}

					{step > 4 && (
						<>
							<ChatMessage>Выберите цвет автомобиля</ChatMessage>
							<div className={styles.colors}>
								{activeModel.colors.map((color) => (
									<ChatColor
										active={color.text === activeColor.text}
										key={color.text}
										onClick={() => chooseColor(color)}
										color={color.hex}
										text={color.text}
									/>
								))}
							</div>
						</>
					)}
					{acceptedColor && <ChatMessage user>{activeColor.text}</ChatMessage>}

					{step > 5 && (
						<>
							<ChatMessage>
								Как планируете приобретать автомобиль?
								<div className={styles.complectations}>
									{getOptions(activeModel.id).map((option) => (
										<div key={option}>
											<Options option={option} setFunction={setActiveOptions} />
										</div>
									))}
								</div>
							</ChatMessage>
							<button
								disabled={!activeOptions.length}
								className={`${styles.button} ${acceptedOption ? styles.buttonActive : ""}`}
								onClick={acceptOption}
							>
								Продолжить
							</button>
						</>
					)}
					{acceptedOption && (
						<ChatMessage user>
							<div dangerouslySetInnerHTML={{__html: chooseOptions(shownActiveOptions)}} />
						</ChatMessage>
					)}

					{step > 6 && <ChatMessage>Спасибо, это были все вопросы.</ChatMessage>}
					{step > 7 && (
						<ChatMessage>
							Проверим на складе наличие подходящего {GLOBAL_BRAND} {activeModel.name} и перезвоним вам через несколько минут
						</ChatMessage>
					)}
					{step > 8 && <ChatForm setSend={setSend} message={message} model={activeModel?.name} />}
					{writes && step !== 0 && <div className={styles.loading}>Печатаю…</div>}
				</div>
				<div ref={scrollRef} style={{visibility: "hidden"}}></div>
			</div>
		</animated.div>
	)
}
